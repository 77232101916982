import axios from 'axios';
import {host, host2} from '../config/configIP';

export const generateC1Report = async (idSession) => {
    const data = await axios(host + "/api/report/generatec1?idSession=" + idSession, {method: 'GET', responseType: 'blob'})
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
    return data;
}

export const createC2Report = async (month, year, uidCenter, uidSpecialty) => {
    const data = await axios.get(host2 + "/api/report/createc2?month=" + month + "&year=" + year + "&uidCenter=" + uidCenter + "&uidSpecialty="
        + uidSpecialty).then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    return data;
}

export const createC3Report = async (month, year, uidCenter) => {
    const data = await axios.get(host2 + "/api/report/createc3?month=" + month + "&year=" + year + "&uidCenter=" + uidCenter)
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
    return data;
}

export const generatePDF = async (html) => {
    const json = {
        html: html
    };
    const data = await axios(host2 + "/api/report/generatepdf", {method: 'POST', data: json, responseType: 'blob'})
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
    return data;
}