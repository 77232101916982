import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import CompanyInfoLoginAlx from './CompanyInfoLoginAlx';
import ButtonAlx from './ButtonAlx';
import ComboBoxAlx from './ComboBoxAlx';
import LoaderSpinnerAlx from './LoaderSpinnerAlx';

import userCookie from '../services/cookiesService';
import {getHealthCenterByUser} from '../services/healthCenterService';
import {getUsernameByToken} from '../services/userService';

const HealthCenterChoosePage = ({handleUserLogout}) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [HCList, setHCList] = useState([]);

    useEffect(() => {
        const cookie = userCookie.get();

        const getHCByToken = async (token) => {
            setIsLoading(true);
            const result = await getHealthCenterByUser(token);
            setHCList(result);
            return result;
        };
        const getUsername = async (token) => {
            const result = await getUsernameByToken(token);
            setUserInfo(result);
            return result;
        }

        getHCByToken(cookie.token).then(() => {
            getUsername(cookie.token).then(() => {setIsLoading(false)});
        });
    }, []);

    const checkHCSelected = () => {
        const hc = document.getElementById("healthCenter");
        const hc_uid = hc.value;
        if (hc_uid !== "") {
            const updateCookie = {
                loggedIn: userCookie.get().loggedIn,
                token: userCookie.get().token,
                uidHC: hc_uid
            }
            userCookie.update(updateCookie);
            navigate("/inicio", {state: {prevPath: window.location.href}});
        }
    }

    return(
        <>
        <div className="container-fluid">
            <div className="row main-content bg-success text-center">
                <CompanyInfoLoginAlx/>
                <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
                    <div className="container-fluid">
                        {(isLoading) ? (<LoaderSpinnerAlx/>) : (
                            ((HCList.length === 0) && (userInfo != null)) ? (
                                <div className="row">
                                    <h4 style={{margin: "50px"}}>Hola {userInfo.name}. Usted todavía no tiene ningún Centro de Salud asignado.
                                        Por favor, comuníquese con la persona Directora del centro.
                                    </h4>
                                    <ButtonAlx onClick={() => handleUserLogout()} type="submit" value="Salir" className="alx-button-normal"/>
                                </div>
                            ) : (
                                <>
                                <div className="row">
                                    <h2
                                    style={{
                                        fontSize: "medium",
                                        marginBottom: "2rem",
                                        textAlign: "center"
                                    }}
                                    >
                                    Seleccione el Centro Médico en donde va a trabajar
                                    </h2>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Centro Médico</label>
                                    <ComboBoxAlx className="form-control" id="healthCenter" list={HCList}/>
                                </div>
                                <div className="row">
                                    <ButtonAlx onClick={() => checkHCSelected()} type="submit" value="Continuar" className="alx-button-normal"/>
                                    <ButtonAlx onClick={() => handleUserLogout()} type="submit" value="Salir" className="alx-button-normal"/>
                                </div>
                                </>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
        </>
    );

}

export default HealthCenterChoosePage;