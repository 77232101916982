import React from 'react';

import userCookie from '../services/cookiesService';

const ModalNewCHAlx = ({toggleModal, insertClinicHistory, patientSelected, newCHIsLoading}) => {

    const cookie = userCookie.get();

    return(
        <div className="modalBackground-search-paciente">
            <div className="modal-dialog" style={{width: "50%"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="HcLabel">{"Nueva evolución: " + patientSelected.name + " " + patientSelected.lastName}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={toggleModal}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <textarea
                                className="form-control"
                                id="info-clinicHistory-alx"
                                placeholder="Redacte su evolución aquí"
                                rows={3}
                                style={{resize: "none"}}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={toggleModal} type="button" className="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button onClick={() => insertClinicHistory(cookie.uidHC, cookie.token, patientSelected.uid)} disabled={(newCHIsLoading) ? (true) : (false)} type="button" className="btn btn-primary">
                            {(newCHIsLoading) ? ("Procesando...") : ("Guardar")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ModalNewCHAlx;