import React, {useState, useEffect} from 'react';

import {onlyNumbers} from '../functions/globalFunctions';
import userCookie from '../services/cookiesService';
import {getAllSpecialties} from '../services/specialtyService';
import {createC2Report, createC3Report, generatePDF} from '../services/reportService';

import ModalAlx from './ModalAlx';

const ReportPage = () => {

    const reportTypes = [{id: 2, name: "C2"}, {id: 3, name: "C3"}];
    const months = [{id: 1, name: "Enero"}, {id: 2, name: "Febrero"}, {id: 3, name: "Marzo"}, {id: 4, name: "Abril"}, {id: 5, name: "Mayo"},
        {id: 6, name: "Junio"}, {id: 7, name: "Julio"}, {id: 8, name: "Agosto"}, {id: 9, name: "Septiembre"}, {id: 10, name: "Octubre"},
        {id: 11, name: "Noviembre"}, {id: 12, name: "Diciembre"}
    ];

    const [reportSelected, setReportSelected] = useState(0);
    const [specialties, setSpecialties] = useState([{}]);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [arrayError, setArrayError] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getSpecialties = async () => {
            const result = await getAllSpecialties();
            setSpecialties(result);
            return result;
        }

        getSpecialties();
    }, [])

    const toggleErrorModal = () => {setOpenErrorModal(!openErrorModal)}

    const ChooseMonth = ({size}) => {
        return (
            <div className={size}>
                <select id="month" className="form-control">
                    <option value="" disabled selected hidden>Elija el mes</option>
                    {months.map((m) => (
                        <option key={m.id} value={m.id}>{m.name}</option>
                    ))}
                </select>
            </div>
        );
    }

    const ChooseYear = ({size}) => {
        return (
            <div className={size}>
                <input placeholder="Ingrese el año (4 DÍGITOS)" onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="year"/>
            </div>
        );
    }

    const ChooseSpecialty = ({size}) => {
        return (
            <div className={size}>
                <select id="specialties" className="form-control">
                    <option value="" disabled selected hidden>Elija la especialidad</option>
                    {specialties.map((s) => (
                        <option key={s.uid} value={s.uid}>{s.name}</option>
                    ))}
                </select>
            </div>
        );
    }

    const handleGenerateC2 = async () => {
        setIsLoading(true);
        let month = document.getElementById("month").value;
        let year = document.getElementById("year").value;
        let uidSpecialty = document.getElementById("specialties").value;
        let result = null;
        if (month === "" || year === "" || year.length !== 4 || uidSpecialty === "") {
            setArrayError([{code: "E00", message: "Por favor, revise nuevamente todos los campos"}]);
            toggleErrorModal();
            setIsLoading(false);
        } else {
            result = await createC2Report(month, year, userCookie.get().uidHC, uidSpecialty).then(async (response) => {
                await generatePDF(response).then((pdf_file) => {
                    const file = new Blob([pdf_file], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setIsLoading(false);
                }).catch(() => {
                    console.log("ERROR");
                    setIsLoading(false);
                });
            });
        }
        return result;
    }

    const handleGenerateC3 = async () => {
        setIsLoading(true);
        let month = document.getElementById("month").value;
        let year = document.getElementById("year").value;
        let result = null;
        if (month === "" || year === "" || year.length !== 4) {
            setArrayError([{code: "E00", message: "Por favor, revise nuevamente todos los campos"}]);
            toggleErrorModal();
            setIsLoading(false);
        } else {
            result = await createC3Report(month, year, userCookie.get().uidHC).then(async (response) => {
                await generatePDF(response).then((pdf_file) => {
                    const file = new Blob([pdf_file], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setIsLoading(false);
                }).catch(() => {
                    console.log("ERROR");
                    setIsLoading(false);
                });
            });
        }
        return result;
    }
    
    return (
        <>
        <div className="form-group col-md-5" style={{margin: "auto", marginTop: "10px"}}>
            <select onChange={(e) => setReportSelected(e.target.value)} id="reportType" className="form-control">
                <option value="" disabled selected hidden>Elija el tipo de reporte</option>
                {reportTypes.map((rt) => (
                    <option key={rt.id} value={rt.id}>{rt.name}</option>
                ))}
            </select>
        </div>

        {(reportSelected == 2) &&
            <>
            <div className="form-row" style={{marginTop: "5%"}}>
                <ChooseMonth size="form-group col-md-4"/>
                <ChooseYear size="form-group col-md-4"/>
                <ChooseSpecialty size="form-group col-md-4"/>
            </div>

            <div className="form-group col-md-1" style={{margin: "auto", whiteSpace: "nowrap", marginTop: "2%"}}>
                <button onClick={() => handleGenerateC2()} type="button" className="btn btn-success btn-md" disabled={(isLoading) ? (true) : (false)}>{(isLoading) ? ("ESPERE...") : ("GENERAR C2")}</button>
            </div>
            </>
        }

        {(reportSelected == 3) && 
            <>
            <div className="form-row" style={{marginTop: "5%"}}>
                <ChooseMonth size="form-group col-md-4"/>
                <ChooseYear size="form-group col-md-4"/>

                <div className="form-group col-md-4">
                    <button onClick={() => handleGenerateC3()} type="button" className="btn btn-success btn-md" disabled={(isLoading) ? (true) : (false)}>{(isLoading) ? ("ESPERE...") : ("GENERAR C3")}</button>
                </div>
            </div>
            </>
        }

        {(openErrorModal) && (<ModalAlx toggleModal={toggleErrorModal} errorMessage={arrayError}/>)}
        </>
    )

}

export default ReportPage;