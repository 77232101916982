import axios from 'axios';
import {host} from '../config/configIP';

export const getRolesByUser = async (uidHealthCenter, token) => {
    const json = {
        Center: uidHealthCenter,
        key: token
    };
    const data = await axios.post(host + "/api/user/getroles", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}