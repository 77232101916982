import axios from 'axios';
import {host} from '../config/configIP';

// Pegamos al endpoint para el logueo de User
export const checkUserLogin = async (name, password) => {
    const json = {
        usr: name,
        pwd: password
    };
    const data = await axios.post(host + "/api/login/login", json)
    .then((response) => {
        console.log(response.data);
        const result = {
            loggedIn: true,
            token: (response.data.token === null) ? (null) : (response.data.token.key),
            uidHC: null
        };
        const json = {
            user: result,
            backResponse: response.data
        };
        return json;
    }, (error) => {
        return error;
    });
    return data;
}

export const getUsernameByToken = async (token) => {
    const json = {
        key: token
    };
    const data = await axios.post(host + "/api/user/getPublicName", json)
    .then((response) => {
        console.log(response.data);
        return response.data;
    }, (error) => {
        console.log(error);
        return error;
    });
    return data;
}