import axios from 'axios';
import {host} from '../config/configIP';

export const getSpecialtyByUser = async (token) => {
    const json = {
        key: token
    };
    const data = await axios.post(host + "/api/user/getSpecialties", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const isSpecialtyOpen = async (token, center_uid) => {
    const json = {
        Token: {
            key: token
        },
        centerUID: center_uid
    };
    const data = await axios.post(host + "/api/shift/getopened", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const closeSpecialtySession = async (token, center_uid) => {
    const json = {
        Token: {
            key: token
        },
        centerUID: center_uid
    };
    const data = await axios.post(host + "/api/shift/close", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const openSpecialtySession = async (token, center_uid, specialty_uid) => {
    const json = {
        Token: {
            key: token
        },
        centerUID: center_uid,
        specialtyUID: specialty_uid
    };
    const data = await axios.post(host + "/api/shift/open", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const getAllSpecialties = async () => {
    const data = await axios.get(host + "/api/specialty/getall").then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
    return data;
}