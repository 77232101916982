// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

 #wrapper {
    overflow-x: hidden;
 }

 .navbar{
   background-color: #1fa64a;
 }

 .nav-link{
   color: white;
   cursor: pointer;
 }

 .nav-link:hover{
   color: lightsalmon;
 }

 



 .sidebar-heading{
   background-color: #1fa64a;
   text-align: center;
   color: white;
 }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

/* ---------- AGREGADO POR JUANCHI (SECCIÓN ACTIVA EN SIDEBAR)---------- */
.active-link-alx, .active-link-alx:hover {
  font-weight: bold;
  color: #1fa64a;
}
/* -------------------- */

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

`, "",{"version":3,"sources":["webpack://./src/css/index.css"],"names":[],"mappings":";;CAEC;IACG,kBAAkB;CACrB;;CAEA;GACE,yBAAyB;CAC3B;;CAEA;GACE,YAAY;GACZ,eAAe;CACjB;;CAEA;GACE,kBAAkB;CACpB;;;;;;CAMA;GACE,yBAAyB;GACzB,kBAAkB;GAClB,YAAY;CACd;;AAED;EACE,iBAAiB;EACjB,mBAAmB;EAInB,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA,0EAA0E;AAC1E;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA,yBAAyB;;AAEzB;EACE;IACE,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":["\n\n #wrapper {\n    overflow-x: hidden;\n }\n\n .navbar{\n   background-color: #1fa64a;\n }\n\n .nav-link{\n   color: white;\n   cursor: pointer;\n }\n\n .nav-link:hover{\n   color: lightsalmon;\n }\n\n \n\n\n\n .sidebar-heading{\n   background-color: #1fa64a;\n   text-align: center;\n   color: white;\n }\n\n#sidebar-wrapper {\n  min-height: 100vh;\n  margin-left: -15rem;\n  -webkit-transition: margin .25s ease-out;\n  -moz-transition: margin .25s ease-out;\n  -o-transition: margin .25s ease-out;\n  transition: margin .25s ease-out;\n}\n\n#sidebar-wrapper .sidebar-heading {\n  padding: 0.875rem 1.25rem;\n  font-size: 1.2rem;\n}\n\n#sidebar-wrapper .list-group {\n  width: 15rem;\n}\n\n#page-content-wrapper {\n  min-width: 100vw;\n}\n\n#wrapper.toggled #sidebar-wrapper {\n  margin-left: 0;\n}\n\n/* ---------- AGREGADO POR JUANCHI (SECCIÓN ACTIVA EN SIDEBAR)---------- */\n.active-link-alx, .active-link-alx:hover {\n  font-weight: bold;\n  color: #1fa64a;\n}\n/* -------------------- */\n\n@media (min-width: 768px) {\n  #sidebar-wrapper {\n    margin-left: 0;\n  }\n\n  #page-content-wrapper {\n    min-width: 0;\n    width: 100%;\n  }\n\n  #wrapper.toggled #sidebar-wrapper {\n    margin-left: -15rem;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
