import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import CompanyInfoLoginAlx from './CompanyInfoLoginAlx';
import LoaderSpinnerAlx from './LoaderSpinnerAlx';
import InputAlx from './InputAlx';
import ButtonAlx from './ButtonAlx';
import ModalOKAlx from './ModalOKAlx';
import ModalAlx from './ModalAlx';
import FooterAlx from './FooterAlx';

// import '../css/login.css';

import {onlyNumbers} from '../functions/globalFunctions';
import {getToken, registerUser} from '../services/registerService';

const RegisterPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [openOKModal, setOpenOKModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [messageOKModal, setMessageOKModal] = useState("");
    const [arrayError, setArrayError] = useState([]);

    useEffect(() => {
        const getTokenKey = async () => {
            const result = await getToken();
            setToken(result.key);
            console.log(result.key);
            return result;
        }

        getTokenKey();
    }, []);

    const toggleModalOK = () => {setOpenOKModal(!openOKModal)}
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const handleOnClickRegister = async () => {
        //e.preventDefault();
        setIsLoading(true);
        const username = document.getElementById("username");
        const password = document.getElementById("password");
        const confPassword = document.getElementById("confPassword");
        const mail = document.getElementById("mail");
        const code = document.getElementById("areaCode").value;
        const number = document.getElementById("phoneNumber").value;
        const phoneNumber = "0" + code + "15" + number;
        await registerUser(token, username.value, phoneNumber, mail.value, password.value, confPassword.value)
        .then((response) => {
            setIsLoading(false);
            if (response.length !== 0) {
                setArrayError(response);
                toggleModal();
                password.style.borderColor = "#1fa64a";
                confPassword.style.borderColor = "#1fa64a";
                mail.style.borderColor = "#1fa64a";
                username.style.borderColor = "#1fa64a";
                for (var i=0; i< response.length; i++) {
                    switch (response[i].code) {
                        case "P00":
                        case "P01":
                            password.style.borderColor = "crimson";
                            confPassword.style.borderColor = "crimson";
                            break;
                        case "M00":
                            mail.style.borderColor = "crimson";
                            break;
                        case "U00":
                            username.style.borderColor = "crimson";
                            break;
                        default:
                            break;
                    }
                }
            } else {
                password.style.borderColor = "#1fa64a";
                confPassword.style.borderColor = "#1fa64a";
                mail.style.borderColor = "#1fa64a";
                username.style.borderColor = "#1fa64a";
                setMessageOKModal("Usuario registrado correctamente");
                toggleModalOK();
            }
        });
    }

    return(
        <>
        <div className="container-fluid">
            <div className="row main-content bg-success text-center">
                <CompanyInfoLoginAlx/>
                <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
                    <div className="container-fluid">
                        <div className="row">
                            <h2>Registro</h2>
                        </div>
                        <div className="row">
                            <form control className="form-group">
                                <div className="row">
                                    <InputAlx onKeyDown={() => {}} type="text" name="username" id="username" className="alx-input-normal" placeholder="Nombre (mínimo 8 caracteres)"/>
                                </div>
                                <div className="row">
                                    <InputAlx onKeyDown={() => {}} type="password" name="password" id="password" className="alx-input-normal" placeholder="Contraseña (mínimo 6 caracteres)"/>
                                </div>
                                <div className="row">
                                    <InputAlx onKeyDown={() => {}} type="password" name="confPassword" id="confPassword" className="alx-input-normal" placeholder="Confirmar contraseña"/>
                                </div>  
                                <div className="row">
                                    <InputAlx onKeyDown={() => {}} type="email" name="mail" id="mail" className="alx-input-normal" placeholder="Correo electrónico"/>
                                </div>
                                <div className="row-table">
                                    <label>0</label>
                                    <InputAlx onKeyDown={(e) => onlyNumbers(e)} type="tel" name="areaCode" id="areaCode" className="alx-input-normal" placeholder="Código de área"/>
                                    <label>15</label>
                                    <InputAlx onKeyDown={(e) => onlyNumbers(e)} type="tel" name="phoneNumber" id="phoneNumber" className="alx-input-normal" placeholder="Número de celular"/>
                                </div>
                                <div className="row">
                                    {(isLoading) ? (<LoaderSpinnerAlx/>) : (
                                        <ButtonAlx onClick={handleOnClickRegister} type="button" value="Registrarse" className="alx-button-normal"/>
                                    )}
                                </div>
                                <div className="row">
                                    <Link to="/">Volver a Login</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterAlx/>
        {(openOKModal) && (<ModalOKAlx toggleModal={toggleModalOK} message={messageOKModal}/>)}
        {(openModal) ? (<ModalAlx toggleModal={toggleModal} errorMessage={arrayError}/>) : (null)}
        </>
    );

}

export default RegisterPage;