// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acordeon{
  float: left;
}

.filtroHC{
  float: left;
  margin-top: 2rem;
}

.BtnRedactar{
  display: flex;
  float: left;
  margin-top: 2rem;
}

.espacio{
  margin-left: 1rem;
}

.datosDoc{
  font-weight: bold;
  text-decoration: none;
}

.info{
  font-weight: lighter;
  text-decoration: none;
  
}

/* AGREGADO POR JUANCHI */

.tab-active {
  color: black;
  background-color: white;
  /* border: solid #ccc;
  border-width: 1px 1px 0 1px; */
}

.tab-nonactive, .tab-nonactive:hover {
  color: darkcyan;
  background-color: white;
  /* border-bottom: black solid 1px !important; */
}

/* MODIFICO CLASE QUE UTILIZA ACCORDION.HEADER DE REACT BOOTSTRAP PARA QUE NO MOSTRARA LOS TÍTULOS
DE HISTORIA CLÍNICA COMO BOTÓN */
.accordion-button {
  background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}`, "",{"version":3,"sources":["webpack://./src/css/historiaclinica.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;;AAEvB;;AAEA,yBAAyB;;AAEzB;EACE,YAAY;EACZ,uBAAuB;EACvB;gCAC8B;AAChC;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,+CAA+C;AACjD;;AAEA;gCACgC;AAChC;EACE,gBAAgB;CACjB,cAAc;CACd,YAAY;CACZ,aAAa;CACb,eAAe;CACf,gBAAgB;AACjB","sourcesContent":[".acordeon{\n  float: left;\n}\n\n.filtroHC{\n  float: left;\n  margin-top: 2rem;\n}\n\n.BtnRedactar{\n  display: flex;\n  float: left;\n  margin-top: 2rem;\n}\n\n.espacio{\n  margin-left: 1rem;\n}\n\n.datosDoc{\n  font-weight: bold;\n  text-decoration: none;\n}\n\n.info{\n  font-weight: lighter;\n  text-decoration: none;\n  \n}\n\n/* AGREGADO POR JUANCHI */\n\n.tab-active {\n  color: black;\n  background-color: white;\n  /* border: solid #ccc;\n  border-width: 1px 1px 0 1px; */\n}\n\n.tab-nonactive, .tab-nonactive:hover {\n  color: darkcyan;\n  background-color: white;\n  /* border-bottom: black solid 1px !important; */\n}\n\n/* MODIFICO CLASE QUE UTILIZA ACCORDION.HEADER DE REACT BOOTSTRAP PARA QUE NO MOSTRARA LOS TÍTULOS\nDE HISTORIA CLÍNICA COMO BOTÓN */\n.accordion-button {\n  background: none;\n\tcolor: inherit;\n\tborder: none;\n\tfont: inherit;\n\tcursor: pointer;\n\toutline: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
