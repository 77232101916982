import React from 'react';
import {Route, Routes} from 'react-router-dom';

import LoginPage from '../components/LoginPage';
import RegisterPage from '../components/RegisterPage';

const RouteLogFalse = ({handleUserLogin}) => {
    return (
        <>
        <Routes>
            <Route path="/" element={<LoginPage handleUserLogin={handleUserLogin}/>}/>
            <Route path="/reg" element={<RegisterPage/>}/>
            {/* <Route path="*" element={<Navigate to="/"/>}/> */}
        </Routes>
        </>
    )
}

export default RouteLogFalse;