import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import LoaderSpinnerAlx from './LoaderSpinnerAlx';
import ModalOKAlx from './ModalOKAlx';
import ModalAlx from './ModalAlx';
import ModalConfirmation from './ModalConfirmation';

import userCookie from '../services/cookiesService';
import {closeSpecialtySession} from '../services/specialtyService';
import {getAllShifts, updateShiftAttentionDate, updateShiftEndDate, cancelShift} from '../services/shifterService';
import {generateC1Report} from '../services/reportService';

const ShifterPage = ({specialtyOpen, setSessionOpen}) => {

    const cookie = userCookie.get();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isStartLoading, setIsStartLoading] = useState(false);
    const [buttonEndShift, setButtonEndShift] = useState(false);
    const [buttonPressed, setButtonPressed] = useState(null);
    const [shifts, setShifts] = useState([]);
    const [openOKModal, setOpenOKModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openConfirmationModal, setConfirmationModal] = useState(false);
    const [arrayError, setArrayError] = useState([]);
    const [openShiftBtnPressed, setOpenShiftBtnPressed] = useState(null);
    const [shiftToCancel, setShiftToCancel] = useState(null);

    useEffect(() => {
        getShifts(cookie.uidHC, cookie.token);
    }, [cookie.uidHC, cookie.token]);

    const getShifts = async (uidHC, token) => {
        const result = await getAllShifts(uidHC, token);
        setShifts(result);
        return result;
    }

    const toggleModalOK = () => {setOpenOKModal(!openOKModal)}
    const toggleErrorModal = () => {setOpenErrorModal(!openErrorModal)}
    const toggleConfirmationModal = () => {setConfirmationModal(!openConfirmationModal)}

    const closeSession = async () => {
        setIsLoading(true);
        const result = await closeSpecialtySession(cookie.token, cookie.uidHC).then(async (response) => {
            await generateC1Report(response).then((pdf_file) => {
                const file = new Blob([pdf_file], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setIsLoading(false);
                setSessionOpen(false);
                navigate("/abrirturnero");
            }).catch(() => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });
        return result;
    }

    const openShift = async (e, shiftUID) => {
        setOpenShiftBtnPressed(e.target.id);
        setIsStartLoading(true);
        const result = await updateShiftAttentionDate(shiftUID);
        setIsStartLoading(false);
        return result;
    }

    const closeShift = async (shiftUID, specialtyUID) => {
        setButtonEndShift(true);
        setButtonPressed(shiftUID);
        const diagnosis = document.getElementById(shiftUID).value;
        let result = null;
        if (diagnosis === "") {
            setButtonEndShift(false);
            setButtonPressed(null);
            setArrayError([{code: "E00", message: "Por favor, ingrese un diagnóstico para el turno"}]);
            toggleErrorModal();
        } else {
            result = await updateShiftEndDate(shiftUID, specialtyUID, diagnosis).then((response) => {
                setButtonEndShift(false);
                setButtonPressed(null);
                (response === 1) && (toggleModalOK());
                getShifts(cookie.uidHC, cookie.token);
            });
        }
        return result;
    }

    const handleCancelShift = async (shiftUID) => {
        setShiftToCancel(shiftUID);
        toggleConfirmationModal();
        // let result = null;
        // if () {
        //     result = await cancelShift(shiftUID).then(async () => {
        //         setBtnYes(false);
        //         toggleConfirmationModal();
        //         await getShifts(cookie.uidHC, cookie.token);
        //     });
        // }
        // return result;
        //const result = await cancelShift(shiftUID);
    }

    return(
        <>
        <br/>
        {(isLoading) ? (<LoaderSpinnerAlx/>) : (
            <button onClick={() => closeSession()} type="button" className="btn btn-success btn-lg alx-btn-turno">
                Finalizar Jornada {(specialtyOpen !== null) && ("(" + specialtyOpen.specialty + ")")}
            </button>
        )}
        <br/>
        <br/>
        <div className="table-responsive">
            <table className="table table-hover">
                <thead className="table-dark">
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Acción</th>
                        <th scope="col">Recepción</th>
                        <th scope="col">Consulta</th>
                        <th scope="col">Finalizado</th>
                        <th scope="col">Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {shifts.map((s) => (
                        <tr key={s.uid}>
                            <td>{s.name + " " + s.lastName}</td>
                            <td>
                                {(s.attentionDate === null) ? (
                                    <>
                                    <button id={s.uid} onClick={(e) => openShift(e, s.uid).then(() => {
                                        navigate("/historiaclinica", {state:{patient_uid: s.patientUID}});
                                    })} type="button" className="btn btn-success btn-sm" disabled={((isStartLoading) && (openShiftBtnPressed === s.uid)) ? (true) : (false)}>
                                        {((isStartLoading) && (openShiftBtnPressed === s.uid)) ? ("Procesando...") : ("Ingresar")}
                                    </button>
                                    <button id={s.uid} onClick={() => handleCancelShift(s.uid)} type="button"
                                    className="btn btn-danger btn-sm" style={{marginLeft: "5px"}}>
                                        Cancelar
                                    </button>
                                    </>
                                ) : (
                                    (s.endDate !== null) ? ("Turno cerrado") : (
                                        ((buttonEndShift === true) && (buttonPressed === s.uid)) ? (<LoaderSpinnerAlx/>) : (
                                        <button onClick={() => closeShift(s.uid, specialtyOpen.uid)} key={s.uid} id={"button" + s.uid} type="button" className="btn btn-secondary btn-sm">
                                            Finalizar
                                        </button>
                                        )
                                    )
                                )}
                            </td>
                            <td>{s.registerDate}</td>
                            <td>{(s.attentionDate === null) ? ("---") : (s.attentionDate)}</td>
                            <td>{(s.endDate === null) ? ("---") : (s.endDate)}</td>
                            <td>{(s.attentionDate === null) ? ("Sin diagnóstico") : (
                                (s.endDate === null) ? (
                                    <textarea key={s.uid} id={s.uid} placeholder="Ingrese un diagnóstico" className="alx-turno-diagnosis"></textarea>
                                ) : (s.diagnosis)
                            )}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        {(openOKModal) && (<ModalOKAlx toggleModal={toggleModalOK} message="El turno se ha cerrado correctamente"/>)}
        {(openErrorModal) && (<ModalAlx toggleModal={toggleErrorModal} errorMessage={arrayError}/>)}
        {(openConfirmationModal) && (<ModalConfirmation toggleModal={toggleConfirmationModal} shiftUID={shiftToCancel} cancelShift={cancelShift} getShifts={getShifts}/>)}
        </>
    );

}

export default ShifterPage;