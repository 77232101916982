import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import userCookie from '../services/cookiesService';
import {isSpecialtyOpen} from '../services/specialtyService';

const IndexPage = ({setSpecialtyOpen, setSessionOpen}) => {

    const {state} = useLocation(); /* Guardamos la URL previa a cargar Inicio para verificar si viene por el camino de logueo y así
    comporobar si Usuario logueado tiene alguna sesión abierta */

    useEffect(() => {
        if ((state !== null) && (state.prevPath.includes("/hc") === true)) {
            const checkSpecialtyOpen = async (token, center_uid) => {
                const result = await isSpecialtyOpen(token, center_uid);
                if (result.specialty != null) {
                    setSessionOpen(true);
                    setSpecialtyOpen(result);
                } else {
                    setSessionOpen(false);
                }
                return result;
            }
            
            checkSpecialtyOpen(userCookie.get().token, userCookie.get().uidHC);
        }
    }, []);

    return(
        <>
        <div className="container-fluid">
            <h1 className="mt-4">ALEXA - Historia Clínica Digital</h1>
            <hr />
            <br />
            <br />
            <p>
                Sistema de historia clinica digital creado y desarrollado por el
                equipo de <a href="https://www.neosolutions.com.ar/">
                <strong>Neo Solutions</strong>
                </a>
                . Para mas información ingrese a la web y realice su consulta.
            </p>
            <h4>Soporte técnico y datos de contacto.</h4>
            <br />
            <br />
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">David Chaves</h4>
                    <p className="card-text">Coordinador de Soluciones IT.</p>
                    <Link to="" className="card-link">Teléfono: 353 4 084 119</Link>
                    <Link to="" className="card-link">info@neosolutions.com.ar</Link>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Ezequiel Arnaudo</h4>
                    <p className="card-text">Coordinador de Negocios</p>
                    <Link to="" className="card-link">teléfono: 353 4 245 872</Link>
                    <Link to="" className="card-link">info@neosolutions.com.ar</Link>
                </div>
            </div>
            <hr />
        </div>
        </>
    );

}

export default IndexPage;