import axios from 'axios';
import {host} from '../config/configIP';

export const getAllShifts = async (idCenter, idUser) => {
    const json = {
        token: {
            key: idUser
        },
        centerUID: idCenter
    };
    const data = await axios.post(host + "/api/shifter/getall", json)
    .then((response) => {
        console.log(response.data);
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const updateShiftAttentionDate = async (shiftUID) => {
    const json = {
        shiftUID: shiftUID
    };
    const data = await axios.post(host + "/api/shifter/open", json)
    .then((response) => {
        console.log(response.data);
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const updateShiftEndDate = async (shiftUID, specialtyUID, diagnosis) => {
    const json = {
        shiftUID: shiftUID,
        specialtyUID: specialtyUID,
        diagnosis: diagnosis
    };
    const data = await axios.post(host + "/api/shifter/close", json)
    .then((response) => {
        console.log(response.data);
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const insertShift = async (uidHC, userID, uidPatient) => {
    const json = {
        centerUID: uidHC,
        userID: userID,
        patientUID: uidPatient
    };
    const data = await axios.post(host + "/api/shifter/newshift", json)
    .then((response) => {
        return response;
    }, (error) => {
        return error;
    });
    return data;
}

export const cancelShift = async (shiftUID) => {
    const json = {
        shiftUID: shiftUID
    };
    const data = await axios.post(host + "/api/shifter/cancelshift", json)
    .then((response) => {
        return response;
    }).catch((error) => {
        return error;
    });
    return data;
}