import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import userCookie from '../services/cookiesService';
import {getUsernameByToken} from '../services/userService';

const NavbarAlx = ({handleUserLogout}) => {

    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const cookie = userCookie.get();

        const getUsername = async (token) => {
            const result = await getUsernameByToken(token);
            setUserInfo(result);
            return result;
        }
        getUsername(cookie.token);
    }, []);

    const toggleSidebar = (e) => {
        e.preventDefault();
        document.getElementById("wrapper").classList.toggle("toggled");
    }

    return(
        <nav className="navbar navbar-expand-lg border-bottom">
            <button className="btn btn-primary" id="menu-toggle" onClick={(e) => toggleSidebar(e)}>
                Módulos
            </button>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                    {userInfo !== null &&
                        <>
                        <li className="nav-item">
                            <a onClick={() => navigate("/inicio")} className="nav-link" href={() => false} style={{color: "white", cursor: "pointer"}}>Inicio</a>
                        </li>
                            <li className="nav-item">
                                <a className="nav-link" href={() => false} style={{color: "white", cursor: "default"}}>{userInfo.name}</a>
                            </li>
                        <li className="nav-item">
                            <a onClick={() => handleUserLogout()} className="nav-link" href={() => false} style={{color: "white"}}>Salir</a>
                        </li>
                        </>
                    }
                </ul>
            </div>
        </nav>
    );

}

export default NavbarAlx;