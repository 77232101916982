// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alx-open-session-main-title {
    font-family: Cambria, sans-serif;
    font-size: 30px;
    font-style: italic;
    font-weight: normal;
    text-transform: none;
    text-align: center;
}

.alx-open-session-par {
    font-size: 20px;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/css/alxOpenSession.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".alx-open-session-main-title {\n    font-family: Cambria, sans-serif;\n    font-size: 30px;\n    font-style: italic;\n    font-weight: normal;\n    text-transform: none;\n    text-align: center;\n}\n\n.alx-open-session-par {\n    font-size: 20px;\n    font-style: italic;\n    font-weight: lighter;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
