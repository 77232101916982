import Cookies from 'universal-cookie';

const cookieDuration = 24*60*60; // 1 día
const cookies = new Cookies();

const userCookie = {
    set: user => cookies.set("user", user, {
        path: "/",
        sameSite: "lax",
        maxAge: cookieDuration
    }),
    get: () => cookies.get("user"),
    update: newValue => cookies.set("user", newValue, {
        path: "/",
        sameSite: "lax",
        maxAge: cookieDuration
    }),
    remove: () => {
        cookies.remove("user", {path: '/'});
    }
}

export default userCookie;