// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alx-btn-turno {
    margin-left: 1%;
}

.alx-turno-diagnosis {
    width: 200px;
    height: 80px;
    font-size: 15px;
    resize: none;
}

.alx-turno-table-row-head {
    display: table;
    width: 100%;
}
  
.alx-turno-table-body {
    display: block;
    max-height: 250px;
    width: 100%;
    overflow-y: auto;
}

.alx-turno-table-body-row {
    width: 100%;
    display: table;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/css/alxTurnos.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,sBAAsB;AAC1B","sourcesContent":[".alx-btn-turno {\n    margin-left: 1%;\n}\n\n.alx-turno-diagnosis {\n    width: 200px;\n    height: 80px;\n    font-size: 15px;\n    resize: none;\n}\n\n.alx-turno-table-row-head {\n    display: table;\n    width: 100%;\n}\n  \n.alx-turno-table-body {\n    display: block;\n    max-height: 250px;\n    width: 100%;\n    overflow-y: auto;\n}\n\n.alx-turno-table-body-row {\n    width: 100%;\n    display: table;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
