import React from 'react';

const CompanyInfoLoginAlx = () => {
    return(
        <div className="col-md-4 text-center company__info">
            <span className="company__logo">
                {/* <h2 /> */}
            </span>
            <h4 className="company_title">
                ALEXA <br /> Historia Clínica Digital
            </h4>
        </div>
    );
}

export default CompanyInfoLoginAlx;