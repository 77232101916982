import React, {useState} from 'react';

import userCookie from '../services/cookiesService';

const ModalConfirmation = ({toggleModal, shiftUID, cancelShift, getShifts}) => {

    const cookie = userCookie.get();
    const [btnYesPressed, setBtnYesPressed] = useState(false);
    
    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="closeBtn">
                    <button onClick={toggleModal}>&times;</button>
                </div>
                <div className="title">
                    <h1>CONFIRMACIÓN</h1>
                </div>
                <div className="body">
                    <p>¿Desea cancelar el turno?</p>
                </div>
                <div className="footer">
                    <button onClick={async () => {
                        setBtnYesPressed(true);
                        await cancelShift(shiftUID).then(async () => {
                            setBtnYesPressed(false);
                            toggleModal();
                            await getShifts(cookie.uidHC, cookie.token);
                        });
                    }} className="btn btn-success" disabled={(btnYesPressed) ? (true) : (false)}>
                        {(btnYesPressed) ? ("Espere...") : ("SÍ")}
                    </button>
                    <button onClick={toggleModal} className="btn btn-danger">NO</button>
                </div>
            </div>
        </div>
    )

}

export default ModalConfirmation;