import axios from 'axios';
import {host} from '../config/configIP';

export const getAllExtraDataTypes = async () => {
    const data = await axios.get(host + "/api/extradata/getall")
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const insertExtraDataPatient = async (idED, centerUID, token, patientUID, extraData) => {
    const json = {
        idExtraData: idED,
        centerUID: centerUID,
        token: {
            key: token
        },
        patientUID: patientUID,
        data: extraData
    };
    const data = await axios.post(host + "/api/extradata/insertforpatient", json)
    .then((response) => {
        return response.status;
    }, (error) => {
        return error;
    });
    return data;
}

export const getExtraDataByPatient = async (patientUID) => {
    const json = {
        patientUID: patientUID
    };
    const data = await axios.post(host + "/api/extradata/getbypatient", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}