// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Archivo con clase de text inputs*/

.alx-input-normal {
    width: 100%;
	border: 0px solid transparent;
	border-radius: 0;
	border-bottom: 1px solid #aaa;
	padding: 1em .5em .5em;
	padding-left: 2em;
	outline: none;
	margin: 1.5em auto;
	transition: all .5s ease;
	border-color: #1fa64a;
	background-color: white;
}

select.alx-input-normal {color: gray}
.alx-input-normal > option {color: black}`, "",{"version":3,"sources":["webpack://./src/css/alxInputs.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC;IACI,WAAW;CACd,6BAA6B;CAC7B,gBAAgB;CAChB,6BAA6B;CAC7B,sBAAsB;CACtB,iBAAiB;CACjB,aAAa;CACb,kBAAkB;CAClB,wBAAwB;CACxB,qBAAqB;CACrB,uBAAuB;AACxB;;AAEA,yBAAyB,WAAW;AACpC,4BAA4B,YAAY","sourcesContent":["/*Archivo con clase de text inputs*/\n\n.alx-input-normal {\n    width: 100%;\n\tborder: 0px solid transparent;\n\tborder-radius: 0;\n\tborder-bottom: 1px solid #aaa;\n\tpadding: 1em .5em .5em;\n\tpadding-left: 2em;\n\toutline: none;\n\tmargin: 1.5em auto;\n\ttransition: all .5s ease;\n\tborder-color: #1fa64a;\n\tbackground-color: white;\n}\n\nselect.alx-input-normal {color: gray}\n.alx-input-normal > option {color: black}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
