import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Dropdown, Tab, Tabs, Accordion} from 'react-bootstrap';

import ModalSearchPatientAlx from './ModalSearchPatientAlx';
import ModalOKAlx from './ModalOKAlx';
import ModalAlx from './ModalAlx';
import ModalNewCHAlx from './ModalNewCHAlx';

import userCookie from '../services/cookiesService';
import {getAllExtraDataTypes, insertExtraDataPatient, getExtraDataByPatient} from '../services/extraDataService';
import {getClinicHistoryByPatient, insertClinicHistory} from '../services/clinicHistoryService';
import {selectPatientByUID} from '../services/patientService';

const ClinicHistoryPage = () => {

    const location = useLocation();
    const cookie = userCookie.get();
    const [isLoading, setIsLoading] = useState(false);
    const [extraDataTypes, setExtraDataTypes] = useState([]);
    const [extraDataSelected, setExtraDataSelected] = useState({id: null, name: null});
    const [searchPatient, setSearchPatient] = useState(false);
    const [patientSelected, setPatientSelected] = useState(null);
    const [EDPatient, setEDPatient] = useState([]);
    const [CHPatient, setCHPatient] = useState([]);
    const [openOKModal, setOpenOKModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [arrayError, setArrayError] = useState([]);
    const [tabSelected, setTabSelected] = useState(1);
    const [newCHModal, setNewCHModal] = useState(false);
    const [newCHIsLoading, setNewCHIsLoading] = useState(false);
    const [searchRowIsLoading, setSearchRowIsLoading] = useState(false);

    useEffect(() => {
        const getEDTypes = async () => {
            const result = await getAllExtraDataTypes();
            setExtraDataTypes(result);
            return result;
        }
        const getPatientByUID = async (patientUID) => {
            const result = await selectPatientByUID(patientUID);
            setPatientSelected(result);
            return result;
        }

        getEDTypes();
        if (location.state !== null) {
            getPatientByUID(location.state.patient_uid).then(() => {
                getEDByPatient(location.state.patient_uid);
                getCHByPatient(location.state.patient_uid);
            });
        }
    }, [location.state]);

    const toggleSearchPatient = () => {setSearchPatient(!searchPatient)}
    const toggleModalOK = () => {setOpenOKModal(!openOKModal)}
    const toggleErrorModal = () => {setOpenErrorModal(!openErrorModal)}
    const toggleNewCHModal = () => {setNewCHModal(!newCHModal)}

    const handleDropdownItemSelect = (event, ed) => {
        console.log(event);
        console.log(ed);
        setExtraDataSelected({id: event, name: ed.target.text});
    }

    const selectPatient = (patient) => {
        setSearchRowIsLoading(true);
        setPatientSelected(patient);
        getEDByPatient(patient.uid).then(() => {
            getCHByPatient(patient.uid).then(() => {
                setSearchRowIsLoading(false);
                toggleSearchPatient();
            });
        });
    }

    const getEDByPatient = async (patientUID) => {
        const result = await getExtraDataByPatient(patientUID);
        setEDPatient(result);
        return result;
    }

    const getCHByPatient = async (patientUID) => {
        const result = await getClinicHistoryByPatient(patientUID);
        setCHPatient(result);
        return result;
    }

    const insertEDPatient = async (idExtraData, centerUID, token, patientUID) => {
        setIsLoading(true);
        const extraData = document.getElementById("extraDataInput").value;
        if (extraData.length === 0) {
            setArrayError([{code: "E00", message: "Por favor, ingrese la información para el paciente"}]);
            toggleErrorModal();
            setIsLoading(false);
        } else {
            await insertExtraDataPatient(idExtraData, centerUID, token, patientUID, extraData)
            .then((response) => {
                if (response === 200) {
                    toggleModalOK();
                    getEDByPatient(patientUID);
                }
                setIsLoading(false);
            }).catch(() => {
                setArrayError([{code: "E01", message: "Ha habido un error. Por favor, intente nuevamente"}]);
                toggleErrorModal();
                setIsLoading(false);
            });
        }
    }

    const insertClinHist = async (centerUID, token, patientUID) => {
        setNewCHIsLoading(true);
        const info = document.getElementById("info-clinicHistory-alx").value;
        if (info.length === 0) {
            setArrayError([{code: "E00", message: "Por favor, ingrese la información para la historia clínica"}]);
            toggleErrorModal();
            setNewCHIsLoading(false);
        } else {
            await insertClinicHistory(centerUID, token, patientUID, info)
            .then((response) => {
                if (response === 200) {
                    toggleModalOK();
                    getCHByPatient(patientUID);
                }
                setNewCHIsLoading(false);
            }).catch(() => {
                setArrayError([{code: "E01", message: "Ha habido un error. Por favor, intente nuevamente"}]);
                toggleErrorModal();
                setNewCHIsLoading(false);
            });
        }
    }
    
    return(
        <>
        <br/>
        <div className="container-fluid">
            <button type="button" className="btn btn-primary" onClick={() => toggleSearchPatient()}>
                Buscar Paciente
            </button>
        </div>
        <div className="container-fluid" style={{ marginTop: "2rem", display: "flex" }}>
            <Dropdown onSelect={handleDropdownItemSelect} className="dropdown">
                <Dropdown.Toggle className="btn btn-secondary dropdown-toggle" id="dropdownMenuButton" aria-expanded="false">
                    {(extraDataSelected.name === null) ? ("Tipos de extra data") : (extraDataSelected.name)}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {extraDataTypes.map((ed) => (
                        <Dropdown.Item key={ed.id} eventKey={ed.id} className="dropdown-item">{ed.label}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <div className="input-group mb-3 w-25" style={{ left: "1rem" }}>
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                    Agregar Nuevo
                    </span>
                </div>
                <input id="extraDataInput" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                <button type="button" className="btn btn-success"
                onClick={() => insertEDPatient(extraDataSelected.id, cookie.uidHC, cookie.token, patientSelected.uid)}
                disabled={((patientSelected === null) || (extraDataSelected.id === null) || (isLoading)) ? (true) : (false)}>
                    {(isLoading) ? ("Procesando...") : ("Guardar")}
                </button>
            </div>
        </div>

        <div className="container-fluid mt-3">
            <h2>Tabla de Antecedentes del Paciente: {(patientSelected !== null) && (patientSelected.name + " " + patientSelected.lastName)}</h2>
            <br/>
            <Tabs activeKey={tabSelected} onSelect={(t) => setTabSelected(t)} className="nav nav-tabs" role="tablist">
                {extraDataTypes.map((ed) => (
                    <Tab eventKey={ed.id} key={ed.id} title={ed.label} tabClassName={(tabSelected === ed.id) ? ("tab-active") : ("tab-nonactive")}>
                        <div className="tab-content">
                            <div className="container-fluid tab-pane active">
                                <br/>
                                <table className="table">
                                    <tbody>
                                        {EDPatient.map((edp) => (
                                            (edp.idExtraData === ed.id) && (
                                                <tr key={edp.uid} className="table-primary">
                                                    <td>{edp.data}</td>
                                                </tr>
                                            )
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Tab>
                ))}
            </Tabs>
        </div>

        {/* <div className="container filtroHC">
            <button type="button" className="btn btn-outline-primary">
            Consultas
            </button>
            <button type="button" className="btn btn-outline-warning">
            Enfermeria
            </button>
            <button type="button" className="btn btn-outline-success">
            Todas
            </button>
        </div> */}

        {/* PRUEBA ACCORDION REACT BOOTSTRAP */}
        {CHPatient.map((ch) => (
            <div className="container acordeon">
                <div id="accordion" style={{marginTop: "2rem"}}>
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <img src="../hqdefault.jpg" alt=""
                                className="img-thumbnail rounded-circle float-start"
                                width={150} height={150}
                            />
                            <div className="datosDoc">
                                Nombre y Apellido: <a className="info" href={() => false}>{ch.username}</a>
                            </div>
                            <div className="datosDoc">
                                Puesto: <a className="info" href={() => false}>Acá va la especialidad</a>
                            </div>
                            <Accordion>
                                <Accordion.Item eventKey={ch.uid}>
                                    <Accordion.Header className="btn btn-link">
                                        <h5 className="mb-0">
                                            <button
                                            className="btn btn-link"
                                            >
                                            <a href={() => false}>{"Especialidad - " + ch.creationDate}</a>
                                            </button>
                                        </h5>
                                    </Accordion.Header>
                                    <Accordion.Body className="card-body">{ch.info}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        ))}
        {/* -------------------------------- */}

        <div className="container BtnRedactar">
            {/* Button trigger modal */}
            <button onClick={() => toggleNewCHModal()} disabled={(patientSelected === null) ? (true) : (false)} type="button" className="btn btn-primary .btn-sm" data-toggle="modal" data-target="#ModalHC">
                Redactar HC
            </button>
            {/* <button type="button" className="btn btn-secondary .btn-sm espacio">
                Redactar Odontograma
            </button> */}
        </div>
    
        {(searchPatient) && <ModalSearchPatientAlx toggleModal={toggleSearchPatient} selectPatient={selectPatient} searchRowIsLoading={searchRowIsLoading}/>}
        {(newCHModal) && (<ModalNewCHAlx toggleModal={toggleNewCHModal} insertClinicHistory={insertClinHist} patientSelected={patientSelected} newCHIsLoading={newCHIsLoading}/>)}
        {(openOKModal) && (<ModalOKAlx toggleModal={toggleModalOK} message={"La información para " + patientSelected.name + " " + patientSelected.lastName + " se ha registrado correctamente"}/>)}
        {(openErrorModal) && (<ModalAlx toggleModal={toggleErrorModal} errorMessage={arrayError}/>)}
        </>
    );

}

export default ClinicHistoryPage;