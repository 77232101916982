import React from 'react';
import "../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Oval} from 'react-loader-spinner';

const LoaderSpinnerAlx = () => {
    return(
        <div>
            <Oval
                height={50}
                width={50}
                color="#1fa64a"
            />
        </div>
    );
}

export default LoaderSpinnerAlx;