import axios from 'axios';
import {host} from '../config/configIP';

export const getClinicHistoryByPatient = async (patientUID) => {
    const json = {
        patientUID: patientUID
    };
    const data = await axios.post(host + "/api/clinichistory/getbypatient", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const insertClinicHistory = async (centerUID, token, patientUID, info) => {
    const json = {
        centerUID: centerUID,
        token: {
            key: token
        },
        patientUID: patientUID,
        info: info
    };
    const data = await axios.post(host + "/api/clinichistory/insert", json)
    .then((response) => {
        return response.status;
    }, (error) => {
        return error;
    });
    return data;
}