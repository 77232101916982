import React, {useState, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import userCookie from './services/cookiesService';
import {isSpecialtyOpen} from './services/specialtyService';

import RouteLogFalse from './routes/RouteLogFalse';
import RouteLogTrue from './routes/RouteLogTrue';
import HealthCenterChoosePage from './components/HealthCenterChoosePage';
import SidebarAlx from './components/SidebarAlx';
import NavbarAlx from './components/NavbarAlx';
import FooterAlx from './components/FooterAlx';

import './css/alxButtons.css';
import './css/alxInputs.css';
import './css/gestionpacientes.css';
import './css/index.css';
import './css/login.css';
import './css/modal.css';
import './css/alxTurnos.css';
import './css/alxOpenSession.css';
import './css/historiaclinica.css';

const App = () => {

  const navigate = useNavigate();
  /* ------------------------------------------------------------------------- */
  const userInitialState = {
    loggedIn: false,
    user: {
      id: null,
      token: null
    }
  }

  const [user, setUser] = useState(userInitialState);
  const [sessionOpen, setSessionOpen] = useState(false);
  const [specialtyOpen, setSpecialtyOpen] = useState(null);

  useEffect(() => {
    if (userCookie.get() === undefined) {
      return;
    }
    /* SI LA CONDICIÓN DE ARRIBA NO SE CUMPLE CORTAMOS EL USEEFFECT PARA QUE NO CORRA TODO EL CÓDIGO */
    const checkSpecialtyOpen = async (token, center_uid) => {
      const result = await isSpecialtyOpen(token, center_uid);
      if (result.specialty != null) {
        setSessionOpen(true);
        setSpecialtyOpen(result);
      }
      return result;
    }

    checkSpecialtyOpen(userCookie.get().token, userCookie.get().uidHC);
  }, [sessionOpen]);

  if ((userCookie.get() != null) && (user.loggedIn === false)) {
    //setUser({loggedIn: true, user: userCookie.get()});
    setUser(userCookie.get());
  }

  const handleUserLogin = (user) => {
    if (user.loggedIn) {
      userCookie.set(user);
      setUser(user);
    }
  }

  const handleUserLogout = () => {
    //e.preventDefault();
    navigate("/");
    userCookie.remove();
    setUser(userInitialState);
  }
  /* ------------------------------------------------------------------------- */

  return (
    (user.loggedIn === true) ? (
      (window.location.pathname === "/hc") ? (
        <>
        <Routes>
          <Route path="/hc" element={<HealthCenterChoosePage handleUserLogout={handleUserLogout}/>}/>
        </Routes>
        <FooterAlx/>
        </>
      ) : (
        <>
        <div className="d-flex toggled" id="wrapper">
          <SidebarAlx sessionOpen={sessionOpen}/>
          <div id="page-content-wrapper">
            <NavbarAlx handleUserLogout={handleUserLogout}/>
            <RouteLogTrue specialtyOpen={specialtyOpen} setSpecialtyOpen={setSpecialtyOpen} setSessionOpen={setSessionOpen}/>
          </div>
        </div>
        <FooterAlx/>
        </>
      )
    ) : (
      <RouteLogFalse handleUserLogin={handleUserLogin}/>
    )
  );

}

export default App;