import React from 'react';
// import '../css/modal.css';

const ModalAlx = ({toggleModal, errorMessage}) => {
    return(
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="closeBtn">
                    <button onClick={toggleModal}>&times;</button>
                </div>
                <div className="title">
                    <h1>ERROR</h1>
                </div>
                <div className="body">
                    {errorMessage.map(error => (
                        <p key={error.code} value={error.code}>- {error.message + '\n'}</p>
                    ))}
                </div>
                <div className="footer">
                    <button onClick={toggleModal} id="redButton">OK</button>
                </div>
            </div>
        </div>
    );
}

export default ModalAlx;