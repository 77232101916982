import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';

import userCookie from '../services/cookiesService';
import {getRolesByUser} from '../services/roleService';

const SidebarAlx = ({sessionOpen}) => {

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const cookie = userCookie.get();

        const getUserRoles = async (uidHC, token) => {
            const result = await getRolesByUser(uidHC, token);
            console.log(result);
            setRoles(result);
            return result;
        }

        getUserRoles(cookie.uidHC, cookie.token);
    }, []);

    const SidebarSection = ({name, link}) => {
        return(
            <NavLink to={link} className={({isActive}) => isActive ? "list-group-item list-group-item-action bg-light active-link-alx" : "list-group-item list-group-item-action bg-light"}>
                {name}
            </NavLink>
        );
    }

    return(
        <div className="bg-light border-right" id="sidebar-wrapper">
            <div className="sidebar-heading">Alexa </div>
            <div className="list-group list-group-flush">
                <SidebarSection name="Inicio" link="/inicio"/>
                {roles.map((role) => (
                    <>
                    {/* {role.name === "Administrativo" &&
                        <SidebarSection name="Control de C1" link="controlc1.html"/>
                    }
                    {role.name === "Director" &&
                        <SidebarSection name="Gestionar Centro" link="gestionpacientes.html"/>
                    } */}
                    {((role.uid === "10000-00000-3") || (role.uid === "10000-00000-1") || (role.uid === "10000-00000-2")) &&
                        <SidebarSection name="Gestión de Pacientes" link="/paciente"/>
                    }
                    {((role.uid === "10000-00000-2") || (role.uid === "10000-00000-1")) &&
                        <SidebarSection name="Historia Clínica" link="/historiaclinica"/>
                    }
                    {/* {role.name === "Medico" &&
                        <SidebarSection name="Pizarra" link="pizarra.html"/>
                    }
                    {role.name === "Administrativo" &&
                        <SidebarSection name="Servicios" link="servicios.html"/>
                    }
                    {role.name === "Administrativo" &&
                        <SidebarSection name="Tags" link="tags.html"/>
                    } */}
                    {role.uid === "10000-00000-2" &&
                        <SidebarSection name="Turnos" link={(sessionOpen) ? ("/turnero") : ("/abrirturnero")}/>
                    }
                    {((role.uid === "10000-00000-3") || (role.uid === "10000-00000-1")) &&
                        <SidebarSection name="Reportes" link="/reportes"/>
                    }
                    </>
                ))}
            </div>
        </div>
    );

}

export default SidebarAlx;