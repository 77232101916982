import axios from 'axios';
import {host} from '../config/configIP';

export const getToken = async () => {
    const data = await axios.get(host + "/api/register/gettoken")
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const registerUser = async (token, user, phoneNumber, mail, password, confPassword) => {
    const json = {
        Token: {
            key: token
        },
        User: user,
        PhoneNumber: phoneNumber,
        Mail: mail,
        Password: password,
        ConfirmPassword: confPassword
    };
    const data = await axios.post(host + "/api/register/register", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}