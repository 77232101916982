import axios from 'axios';
import {host} from '../config/configIP';

export const getHealthCenterByUser = async (token) => {
    const json = {
        key: token
    };
    const data = await axios.post(host + "/api/user/getCenters", json)
    .then((response) => {
        console.log(response.data);
        return response.data;
    }, (error) => {
        console.log(error);
        return error;
    });
    return data;
}

export const getDoctorByHC = async (uidHC) => {
    const json = {
        uidCenter: uidHC
    };
    const data = await axios.post(host + "/api/user/getuserbycenter", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}