import React from 'react';
// import '../css/alxButtons.css';

const ButtonAlx = ({type, value, className, onClick}) => {
    return (
        <input
        type={type}
        value={value}
        className={className}
        onClick={() => onClick()}
        />
    );
}

export default ButtonAlx;