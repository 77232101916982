import React from 'react';

const ComboBoxAlx = ({className, id, list}) => {
    return(
        <select className={className} id={id}>
            <option selected/>
            {list.map(item => (
                <option key={item.uid} value={item.uid}>{item.name}</option>
            ))}
        </select>
    );
}

export default ComboBoxAlx;