import React, {useState, useEffect} from 'react';

import ModalAlx from './ModalAlx';

import {searchPatientByDNI, searchPatientByLastName} from '../services/patientService';
import userCookie from '../services/cookiesService';

const ModalSearchPatientAlx = ({toggleModal, selectPatient, searchRowIsLoading}) => {

    const Radio = ({id, group, text, isDisabled}) => {
        return(
            <div className="form-check">
                <input className="form-check-input" type="radio" name={group} id={id} value={id} disabled={isDisabled}/>
                <label className="form-check-label" htmlFor={id}>{text}</label>
            </div>
        );
    }

    const cookie = userCookie.get();
    const [searchResult, setSearchResult] = useState([]);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [arrayError, setArrayError] = useState([]);

    useEffect(() => {
        document.getElementById("buttonSearchPatientAlx").disabled = true;
    }, []);

    const toggleErrorModal = () => {setOpenErrorModal(!openErrorModal)}

    const handleSearch = async () => {
        const radioChecked = document.querySelector("input[name='patientSearchFilter']:checked");
        if (radioChecked === null) {
            setArrayError([{code: "E00", message: "Por favor, seleccione un tipo de búsqueda"}]);
            toggleErrorModal();
        } else {
            setSearchIsLoading(true);
            const search = document.getElementById("searchBox").value;
            const searchType = radioChecked.value;
            switch (searchType) {
                case "dni":
                    await searchPatientByDNI(cookie.token, search).then((response) => {
                        setSearchResult(response);
                        setSearchIsLoading(false);
                        document.getElementById("buttonSearchPatientAlx").disabled = true;
                        document.getElementById("searchBox").value = "";
                    });
                    break;
                case "lastname":
                    await searchPatientByLastName(cookie.token, search).then((response) => {
                        setSearchResult(response);
                        setSearchIsLoading(false);
                        document.getElementById("buttonSearchPatientAlx").disabled = true;
                        document.getElementById("searchBox").value = "";
                    });
                    break;
                default:
                    break;
            }
        }
    }

    const checkInputLength = () => {
        const search = document.getElementById("searchBox").value;
        if (search.length > 3) {
            document.getElementById("buttonSearchPatientAlx").disabled = false;
        } else {
            document.getElementById("buttonSearchPatientAlx").disabled = true;
        }
    }

    return(
        // <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modalBackground-search-paciente">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Búsqueda de Pacientes
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={toggleModal}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-6">
                                <label htmlFor="searchBox">Búsqueda</label>
                                <input onKeyUp={() => checkInputLength()} type="text" className="form-control" id="searchBox" placeholder="" />
                            </div>
                            <br />
                            <Radio id="dni" group="patientSearchFilter" text="Buscar por DNI" isDisabled={false}/>
                            <Radio id="lastname" group="patientSearchFilter" text="Buscar por Apellido" isDisabled={false}/>
                            <Radio id="tag" group="patientSearchFilter" text="Buscar por Tags" isDisabled={true}/>
                            {/* <div className="col-md-6">
                                <hr />
                                <label htmlFor="inputTags">Tags</label>
                                <select id="inputTags">
                                    <option selected="">Seleccionar</option>
                                    <option>Covid positivo</option>
                                    <option>Geriátrico</option>
                                    <option>Hipertensión</option>
                                    <option>Otro</option>
                                </select>
                            </div> */}
                            <br/>
                            <table className="table" /*style={{display: "block", overflowX: "auto", whiteSpace: "nowrap", width: "100%"}}*/>
                                <thead>
                                    <tr className="alx-table-row-head">
                                        <th scope="col">Apellido</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">DNI</th>
                                        <th scope="col">Teléfono</th>
                                    </tr>
                                </thead>
                                <tbody className="alx-table-body" /*style={{display: "block", overflowX: "auto", whiteSpace: "nowrap"}}*/>
                                    {searchResult.map((sr) => (
                                        <tr className="rowSearchResultAlx" onClick={() => selectPatient(sr)}>
                                            {(searchRowIsLoading) ? (
                                                <td>Espere...</td>
                                            ) : (
                                                <>
                                                <td>{sr.lastName}</td>
                                                <td>{sr.name}</td>
                                                <td>{sr.dni}</td>
                                                <td>{sr.phone}</td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button onClick={toggleModal} type="button" className="btn btn-secondary" data-dismiss="modal">
                                Cerrar
                            </button>
                            <button onClick={() => handleSearch()} id="buttonSearchPatientAlx" type="button" className="btn btn-primary" disabled={(searchIsLoading) ? (true) : (false)}>
                                {(searchIsLoading) ? ("Procesando...") : ("Buscar")}
                            </button>
                        </div>
                    </div>
                </div>
                {(openErrorModal) && (<ModalAlx toggleModal={toggleErrorModal} errorMessage={arrayError}/>)}
            </div>
        // </div>
    );

}

export default ModalSearchPatientAlx;