import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import InputAlx from './InputAlx';
import ButtonAlx from './ButtonAlx';
import LoaderSpinnerAlx from './LoaderSpinnerAlx';
import CompanyInfoLoginAlx from './CompanyInfoLoginAlx';
import ModalAlx from './ModalAlx';
import FooterAlx from './FooterAlx';

// import '../css/login.css';

import {checkUserLogin} from '../services/userService';

const LoginPage = ({handleUserLogin}) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Lo utilizamos para mostrar Loading o no
    const [openModal, setOpenModal] = useState(false);
    const [arrayError, setArrayError] = useState([]);

    const toggleModal = () => {setOpenModal(!openModal)}

    const handleOnClickButton = async () => {
        setIsLoading(true);
        //e.preventDefault();
        const name = document.getElementById("username").value;
        const password = document.getElementById("password").value;
        await checkUserLogin(name, password).then((response) => {
            setIsLoading(false);
            if (response.backResponse.errors.length === 0) {
                navigate("/hc");
                handleUserLogin(response.user);
            } else {
                setArrayError(response.backResponse.errors);
                toggleModal();
            }
        }).catch(() => {
            setArrayError([{code: "0", message: "ERROR EN EL SERVIDOR"}]);
            toggleModal();
        });
    }

    return (
        <>
        <div className="container-fluid">
            <div className="row main-content bg-success text-center">
                <CompanyInfoLoginAlx/>
                <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
                    <div className="container-fluid">
                        <div className="row">
                            <h2>Iniciar Sesión</h2>
                        </div>
                        <div className="row">
                            <form control className="form-group">
                                <div className="row">
                                    <InputAlx onKeyDown={() => {}} type="text" name="username" id="username" className="alx-input-normal" placeholder="Usuario"/>
                                </div>
                                <div className="row">
                                    <InputAlx onKeyDown={() => {}} type="password" name="password" id="password" className="alx-input-normal" placeholder="Contraseña"/>
                                </div>
                                <div className="row">
                                    {(isLoading) ? (<LoaderSpinnerAlx/>) : (
                                        <ButtonAlx onClick={handleOnClickButton} type="submit" value="Iniciar Sesión" className="alx-button-normal"/>
                                    )}
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <p>
                            Si no tienes una cuenta <Link to="/reg">Registrarse aquí</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterAlx/>
        {(openModal) && (<ModalAlx toggleModal={toggleModal} errorMessage={arrayError}/>)}
        </>
    );

}

export default LoginPage;