import React from 'react';

const ModalOKAlx = ({toggleModal, message}) => {
    return(
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="closeBtn">
                    <button onClick={toggleModal}>&times;</button>
                </div>
                <div className="title">
                    <h1>ÉXITO</h1>
                </div>
                <div className="body">
                    <p>{message}</p>
                </div>
                <div className="footer">
                    <button onClick={toggleModal} id="greenButton">OK</button>
                </div>
            </div>
        </div>
    );
}

export default ModalOKAlx;