import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import userCookie from '../services/cookiesService';
import {getSpecialtyByUser, openSpecialtySession} from '../services/specialtyService';

const OpenSessionPage = ({setSessionOpen}) => {

    const navigate = useNavigate();
    const cookie = userCookie.get();
    const [isLoading, setIsLoading] = useState(false);
    const [specialties, setSpecialties] = useState([]);

    useEffect(() => {
        const getSpecialties = async (token) => {
            const result = await getSpecialtyByUser(token);
            setSpecialties(result);
            console.log(result);
            return result;
        }

        getSpecialties(cookie.token);
    }, [cookie.token, cookie.uidHC]);

    const openSession = async (s) => {
        setIsLoading(true);
        const result = await openSpecialtySession(cookie.token, cookie.uidHC, s.uid).then(() => {
            setIsLoading(false);
            setSessionOpen(true);
            navigate("/turnero");
        }).catch(() => {
            setIsLoading(false);
        });
        return result;
    }

    return(
        <>
        <div className="container">
            <h3 className="alx-open-session-main-title">Seleccione el servicio perteneciente a la jornada</h3>
            <div className="row">
                <div className="col-sm" style={{textAlign: "center"}}>
                    {(isLoading) ? (
                        <p className="alx-open-session-par">Espere...</p>
                        // <div style={{textAlign: "center"}}>
                        //     <LoaderSpinnerAlx/>
                        // </div>
                    ) : (
                        specialties.map((s) => (
                            <button onClick={() => openSession(s)} key={s.uid} type="button" className="btn btn-success btn-lg" style={{margin: "1rem"}}>
                                {s.name}
                            </button>
                        ))
                    )}
                </div>
            </div>
        </div>
        </>
    );

}

export default OpenSessionPage;