import React, {useState, useEffect} from 'react';

import ModalSearchPatientAlx from './ModalSearchPatientAlx';
import LoaderSpinnerAlx from './LoaderSpinnerAlx';
import ModalOKAlx from './ModalOKAlx';
import ModalAlx from './ModalAlx';

import {getCities} from '../services/cityService';
import {registerPatient, updatePatient} from '../services/patientService';
import {getHealthCares} from '../services/healthCareService';
import {getDoctorByHC} from '../services/healthCenterService';
import {insertShift} from '../services/shifterService';
import userCookie from '../services/cookiesService';

import {onlyNumbers} from '../functions/globalFunctions';

//import '../css/index.css';

const PatientPage = () => {

    // const Input = ({onKeyDown, type, className, id}) => {
    //     return(
    //         <input onKeyDown={(e) => onKeyDown(e)} type={type} className={className} id={id}/>
    //     );
    // }

    const cookie = userCookie.get();
    const [isLoading, setIsLoading] = useState(false);
    const [openOKModal, setOpenOKModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [arrayError, setArrayError] = useState([]);
    const [addPatient, setAddPatient] = useState(false);
    const [searchPatient, setSearchPatient] = useState(false);
    const [cities, setCities] = useState([]);
    const [healthCares, setHealthCares] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [isUpdate, setIsUpdate] = useState({state: false, uidPac: null});
    const [messageOKModal, setMessageOKModal] = useState("");
    const [assignShiftIsLoading, setAssignShiftIsLoading] = useState(false);

    useEffect(() => {
        const getAllCities = async () => {
            const result = await getCities();
            setCities(result);
            return result;
        }
        const getAllHealthCares = async () => {
            const result = await getHealthCares();
            setHealthCares(result);
            return result;
        }

        getAllCities();
        getAllHealthCares();
    }, []);

    const toggleAddPatient = () => {setAddPatient(!addPatient)}
    const toggleSearchPatient = () => {setSearchPatient(!searchPatient)}
    const toggleModalOK = () => {setOpenOKModal(!openOKModal)}
    const toggleErrorModal = () => {setOpenErrorModal(!openErrorModal)}

    const getAndFillDoctorsByCenter = async (uidHC) => {
        const result = await getDoctorByHC(uidHC);
        setDoctors(result);
        return result;
    }

    const assignShift = async (e) => {
        e.preventDefault();
        setAssignShiftIsLoading(true);
        let doctorID = document.getElementById("profesional").value;
        if (doctorID === "") {
            setArrayError([{code: "E00", message: "Por favor, seleccione profesional para asignar turno"}]);
            toggleErrorModal();
            setAssignShiftIsLoading(false);
        } else {
            await insertShift(cookie.uidHC, doctorID, isUpdate.uidPac).then((result) => {
                if (result.status === 200) {
                    setMessageOKModal("El turno se ha asignado correctamente");
                    toggleModalOK();
                    setAssignShiftIsLoading(false);
                }
                // if (result.status === 400) {
                //     setArrayError([{code: "E01", message: "Ha habido un error. Por favor, intente nuevamente"}]);
                //     toggleErrorModal();
                //     setAssignShiftIsLoading(false);
                // }
            }).catch(() => {
                setArrayError([{code: "E01", message: "Ha habido un error. Por favor, intente nuevamente"}]);
                toggleErrorModal();
                setAssignShiftIsLoading(false);
            });
        }
    }
    
    const handleOnClickSavePacient = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const lastName = document.getElementById("lastName").value;
        const name = document.getElementById("name").value;
        const address = document.getElementById("address").value;
        const dni = document.getElementById("dni").value;
        const citySel = document.getElementById("city");
        const cityID = citySel.value;
        const birthDay = document.getElementById("birthday").value;
        const gender = document.getElementById("sexo").value;
        let genderLetter = "";
        switch (gender) {
            case "Masculino":
                genderLetter = "M";
                break;
            case "Femenino":
                genderLetter = "F";
                break;
            case "Otro":
                genderLetter = "O";
                break;
            default:
                break;
        }
        const phoneNumber = document.getElementById("telefono").value;
        const healthCareSel = document.getElementById("obraSocial");
        const healthCareID = healthCareSel.value;
        const healthCareNumber = document.getElementById("afiliado").value;
        const isBaby = document.getElementById("isBaby").checked;
        switch (isUpdate.state) {
            case false:
                await registerPatient(cookie.token, lastName, name, dni, isBaby, address, birthDay, genderLetter,
                phoneNumber, healthCareID, healthCareNumber, cityID, cookie.uidHC).then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        setMessageOKModal("Paciente guardado correctamente");
                        toggleModalOK();
                    } else {
                        setArrayError([{code: "E02", message: "Ha habido un error. Por favor, revise los campos de Paciente e intente nuevamente"}]);
                        toggleErrorModal();
                    }
                });
                break;
            case true:
                await updatePatient(cookie.token, lastName, name, dni, isBaby, address, birthDay, genderLetter,
                phoneNumber, healthCareID, healthCareNumber, cityID, cookie.uidHC, isUpdate.uidPac)
                .then(() => {
                    setIsLoading(false);
                    setMessageOKModal("Paciente editado correctamente");
                    toggleModalOK();
                    setIsUpdate({state: false, uidPac: null});
                    document.getElementById("formpac").reset();
                });
                break;
            default:
                break;
        }
    }

    const editPatient = (patient) => {
        getAndFillDoctorsByCenter(cookie.uidHC);
        setIsUpdate({state: true, uidPac: patient.uid});
        if (addPatient === false) {toggleAddPatient()}
        toggleSearchPatient();
        document.getElementById("lastName").value = patient.lastName;
        document.getElementById("name").value = patient.name;
        document.getElementById("address").value = patient.address;
        document.getElementById("dni").value = patient.dni;
        document.getElementById("city").value = patient.idCity;
        document.getElementById("birthday").value = patient.dateBirth;
        //let gender = document.getElementById("sexo").value;
        const genderLetter = patient.sex;
        switch (genderLetter) {
            case "M":
                document.getElementById("sexo").value = "Masculino";
                break;
            case "F":
                document.getElementById("sexo").value = "Femenino";
                break;
            case "O":
                document.getElementById("sexo").value = "Otro";
                break;
            default:
                break;
        }
        document.getElementById("telefono").value = patient.phone;
        document.getElementById("obraSocial").value = patient.idHealthCare;
        document.getElementById("afiliado").value = patient.healthCareNumberId;
        document.getElementById("isBaby").checked = patient.isBaby;
    }

    return(
        <>
        <div className="container-fluid">
            {/* <button className="btn btn-primary" data-toggle="collapse" data-target="#demo">
                Agregar Paciente
            </button> */}
            <button className="btn btn-primary alx-btn-paciente" onClick={() => toggleAddPatient()}>
                Agregar Paciente
            </button>
            {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                Buscar Paciente
            </button> */}
            <button className="btn btn-primary alx-btn-paciente" onClick={() => toggleSearchPatient()}>
                Buscar Paciente
            </button>
        </div>

        {/* De este primer "div" tuve que eliminar "className="collapse"" para que anduviera */}
        <div id="demo">
            <br />
            <form id="formpac" className={(addPatient) ? ("formpacvis") : ("formpacinvis")}>
                <div className="form-row">
                    <div className="form-group col-md-5">
                        <label htmlFor="lastName">Apellido</label>
                        <input type="text" className="form-control" id="lastName"/>
                        {/* <Input onKeyDown={() => {}} type="text" className="form-control" id="lastName"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="name">Nombre</label>
                        <input type="text" className="form-control" id="name"/>
                        {/* <Input onKeyDown={() => {}} type="text" className="form-control" id="name"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="address">Domicilio</label>
                        <input type="text" className="form-control" id="address"/>
                        {/* <Input onKeyDown={() => {}} type="text" className="form-control" id="address"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="dni">N° Documento (sin puntos)</label>
                        <input onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="dni"/>
                        {/* <Input onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="dni"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="city">Localidad</label>
                        <select id="city" className="form-control">
                            <option value="" disabled selected hidden>Localidad</option>
                            {cities.map((c) => (
                                <option key={c.id} value={c.id}>{c.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="birthday">Fecha Nacimiento</label>
                        <input type="date" className="form-control" id="birthday"/>
                        {/* <Input onKeyDown={() => {}} type="date" className="form-control" id="birthday"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="sexo">Sexo</label>
                        <select id="sexo" className="form-control">
                            <option value="" disabled selected hidden>Sexo</option>
                            <option>Masculino</option>
                            <option>Femenino</option>
                            <option>Otro</option>
                        </select>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="telefono">Teléfono</label>
                        <input onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="telefono"/>
                        {/* <Input onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="telefono"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="obraSocial">Obra Social</label>
                        <select id="obraSocial" className="form-control">
                            {/* <option value="" disabled selected hidden>Obra Social</option> */}
                            {healthCares.map((hc) => (
                                <option key={hc.id} value={hc.id} selected={(hc.id === 101) ? (true) : (false)}>{hc.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="afiliado">N° Afiliado</label>
                        <input onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="afiliado"/>
                        {/* <Input onKeyDown={(e) => onlyNumbers(e)} type="text" className="form-control" id="afiliado"/> */}
                    </div>
                    <div className="form-group col-md-5">
                        <input type="checkbox" id="isBaby" className="alx-checkbox-paciente"/>
                        <label htmlFor="isBaby"> ¿Es recién nacido/a?</label>
                    </div>
                </div>
                {(isUpdate.state) && (
                    <div className="form-group col-md-5" style={{marginLeft: "-15px"}}>
                        <label htmlFor="profesional">Elegir profesional para asignar Turno</label>
                        <select id="profesional" className="form-control">
                            <option value="" disabled selected hidden>Profesional</option>
                            {doctors.map((d) => (
                                <option key={d.id} value={d.id}>{d.username}</option>
                            ))}
                        </select>
                        <button type="submit" className="btn btn-primary" onClick={(e) => assignShift(e)} disabled={(assignShiftIsLoading) ? (true) : (false)} style={{marginTop: "15px"}}>
                            {(assignShiftIsLoading) ? ("Espere...") : ("ASIGNAR TURNO")}
                        </button>
                    </div>
                )}
                {(isLoading) ? (<LoaderSpinnerAlx/>) : (
                    <>
                    <button type="submit" className="btn btn-primary" onClick={(e) => handleOnClickSavePacient(e)}>
                        {(isUpdate.state) ? ("EDITAR") : ("GUARDAR")}
                    </button>
                    <button onClick={() => setIsUpdate({state: false})} type="reset" className="btn btn-primary alx-btn-cancel">
                        CANCELAR
                    </button>
                    </>
                )}
            </form>
        </div>

        {/* Modal */}
        {(searchPatient) && <ModalSearchPatientAlx toggleModal={toggleSearchPatient} selectPatient={editPatient}/>}
        {(openOKModal) && (<ModalOKAlx toggleModal={toggleModalOK} message={messageOKModal}/>)}
        {(openErrorModal) && (<ModalAlx toggleModal={toggleErrorModal} errorMessage={arrayError}/>)}
        </>
    );

}

export default PatientPage;