import axios from 'axios';
import {host} from '../config/configIP';

export const registerPatient = async (token, lastName, name, dni, isBaby, address, birthDay, sex, phoneNumber, healthCareID, healthCareNumber, cityID, uidCenter) => {
    const json = {
        Token: {
            key: token
        },
        Name: name,
        LastName: lastName,
        Address: address,
        Dni: dni,
        DateBirth: birthDay,
        Sex: sex,
        Phone: phoneNumber,
        HealthCareNumberId: healthCareNumber,
        IsBaby: isBaby,
        IdCity: cityID,
        IdHealthCare: healthCareID,
        UIDCenter: uidCenter
    };
    const data = await axios.post(host + "/api/patient/insert", json)
    .then((response) => {
        return response;
    }, (error) => {
        return error;
    });
    return data;
}

export const searchPatientByDNI = async (token, dni) => {
    const json = {
        Token: {
            key: token
        },
        q: dni
    };
    const data = await axios.post(host + "/api/patient/bydni", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const searchPatientByLastName = async (token, lastName) => {
    const json = {
        Token: {
            key: token
        },
        q: lastName
    };
    const data = await axios.post(host + "/api/patient/bylastname", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}

export const updatePatient = async (token, lastName, name, dni, isBaby, address, birthDay, sex, phoneNumber, healthCareID, healthCareNumber, cityID, uidCenter, uidPatient) => {
    const json = {
        Token: {
            key: token
        },
        Name: name,
        LastName: lastName,
        Address: address,
        Dni: dni,
        DateBirth: birthDay,
        Sex: sex,
        Phone: phoneNumber,
        HealthCareNumberId: healthCareNumber,
        IsBaby: isBaby,
        IdCity: cityID,
        IdHealthCare: healthCareID,
        UIDCenter: uidCenter,
        uid: uidPatient
    }
    const data = await axios.post(host + "/api/patient/update", json)
    .then((response) => {
        console.log(response.data);
        return response.data;
    }, (error) => {
        console.log(error);
        return error;
    });
    return data;
}

export const selectPatientByUID = async (patientUID) => {
    const json = {
        UID: patientUID
    };
    const data = await axios.post(host + "/api/patient/getbyuid", json)
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}