import axios from 'axios';
import {host} from '../config/configIP';

export const getCities = async () => {
    const data = await axios.get(host + "/api/city/getall")
    .then((response) => {
        return response.data;
    }, (error) => {
        return error;
    });
    return data;
}