import React from 'react';
// import '../css/alxInputs.css';

const InputAlx = ({onKeyDown, type, name, id, className, placeholder}) => {
    return (
        <input
        onKeyDown={(e) => onKeyDown(e)}
        type={type}
        name={name}
        id={id}
        className={className}
        placeholder={placeholder}
        />
    );
}

export default InputAlx;