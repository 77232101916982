// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Archivo con clase de botones*/

.alx-button-normal
{
	
		transition: all .5s ease;
		width: 70%;
		border-radius: 30px;
		color: #1fa64a;
		font-weight: 600;
		background-color: #fff;
		border: 1px solid #1fa64a;
		margin-top: 1.5em;
		margin-bottom: 1em;

	
}

/*Recordar agregar la clase del boton a la animacion en caso de requerir la misma animacion*/

.alx-button-normal:hover, .alx-button-normal:focus {
	background-color: lightgray;
	color: #1fa64a;
}
`, "",{"version":3,"sources":["webpack://./src/css/alxButtons.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;;;EAGE,wBAAwB;EACxB,UAAU;EACV,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;;;AAGpB;;AAEA,4FAA4F;;AAE5F;CACC,2BAA2B;CAC3B,cAAc;AACf","sourcesContent":["/*Archivo con clase de botones*/\n\n.alx-button-normal\n{\n\t\n\t\ttransition: all .5s ease;\n\t\twidth: 70%;\n\t\tborder-radius: 30px;\n\t\tcolor: #1fa64a;\n\t\tfont-weight: 600;\n\t\tbackground-color: #fff;\n\t\tborder: 1px solid #1fa64a;\n\t\tmargin-top: 1.5em;\n\t\tmargin-bottom: 1em;\n\n\t\n}\n\n/*Recordar agregar la clase del boton a la animacion en caso de requerir la misma animacion*/\n\n.alx-button-normal:hover, .alx-button-normal:focus {\n\tbackground-color: lightgray;\n\tcolor: #1fa64a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
