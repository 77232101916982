import React from 'react';

// import '../css/login.css';

const FooterAlx = () => {
    return(
        <div className="container-fluid text-center footer">
            Copyright © by{" "}
            <a href="https://www.neosolutions.com.ar/">Neo Solutions 2021.</a>
            <p />
        </div>
    );
}

export default FooterAlx;