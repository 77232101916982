import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import IndexPage from '../components/IndexPage';
import PatientPage from '../components/PatientPage';
import OpenSessionPage from '../components/OpenSessionPage';
import ShifterPage from '../components/ShifterPage';
import ClinicHistoryPage from '../components/ClinicHistoryPage';
import ReportPage from '../components/ReportPage';

import userCookie from '../services/cookiesService';

const RouteLogTrue = ({specialtyOpen, setSpecialtyOpen, setSessionOpen}) => {

    /* CON LAS COOKIES FUERZO A QUE SI EL USUARIO ESTÁ LOGUEADO PERO TODAVÍA NO SELECCIONÓ NINGÚN CENTRO,
    NO PUEDA IR A NINGUNA OTRA RUTA */
    const cookie = userCookie.get();

    return (
        <>
        <Routes>
            <Route path="/inicio" element={(cookie.uidHC === null) ? (<Navigate to="/hc"/>) : (<IndexPage setSpecialtyOpen={setSpecialtyOpen} setSessionOpen={setSessionOpen}/>)}/>
            <Route path="/paciente" element={(cookie.uidHC === null) ? (<Navigate to="/hc"/>) : (<PatientPage/>)}/>
            <Route
                path="/abrirturnero"
                element={(cookie.uidHC === null) ? (<Navigate to="/hc"/>) : (<OpenSessionPage setSessionOpen={setSessionOpen}/>)}
            />
            <Route
                path="/turnero"
                element={(cookie.uidHC === null) ? (<Navigate to="/hc"/>) : (<ShifterPage specialtyOpen={specialtyOpen} setSessionOpen={setSessionOpen}/>)}
            />
            <Route path="/historiaclinica" element={(cookie.uidHC === null) ? (<Navigate to="/hc"/>) : (<ClinicHistoryPage/>)}/>
            <Route path="/reportes" element={(cookie.uidHC === null) ? (<Navigate to="/hc"/>) : (<ReportPage/>)}/>
            <Route path="*" element={<Navigate to="/inicio"/>}/>
        </Routes>
        </>
    )
}

export default RouteLogTrue;